import React from 'react'

export default function StartEndDatePickers ({
  endDate,
  onStartChange,
  onEndChange,
  startDate
}) {
  return (
    <>
      <label>
        Start Date:
        <input
          type='date'
          defaultValue={startDate.toISOString().substr(0, 10)}
          onBlur={e => onStartChange(new Date(e.target.value))}
        />
      </label>

      <label>
        End Date:
        <input
          type='date'
          defaultValue={endDate.toISOString().substr(0, 10)}
          onChange={e => onEndChange(new Date(e.target.value))}
        />
      </label>
    </>
  )
}
