import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import auth from '../../components/authentic'
import history from '../../shared/history'

const AuthPages = ({ setIsLoggedIn }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (auth.isLoggedIn()) {
        setIsLoggedIn(true)
      }
    })
    return unlisten
  }, [setIsLoggedIn])

  return (
    <Switch>
      <Route exact path='/login' component={auth.login} />
      <Route exact path='/signup' component={auth.signup} />
      <Route exact path='/change-password-request' component={auth.changePasswordRequest} />
      <Route exact path='/change-password/:email/:changeToken' component={auth.changePassword()} />
    </Switch>
  )
}

export default AuthPages
