export function getReadableDateFromISO (date) {
  return new Date(date).getUTCMonth() + 1 + '/' +
  new Date(date).getUTCDate() + '/' +
  new Date(date).getUTCFullYear()
}

export function getMonthAndDay (date) {
  const d = new Date(date)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec']
  return monthNames[d.getMonth()] + ' ' + d.getDate()
}

export function getBehindDate (behind) {
  const now = new Date()
  now.setDate(now.getDate() - behind)
  now.setHours(0)
  now.setMinutes(1)
  return now
}

export function getWeekCount (date1, date2) {
  const diff = new Date(date2).getTime() - new Date(date1).getTime()
  return Math.ceil(diff / (1000 * 60 * 60 * 24 * 7))
}

export function getDayOfWeek (date) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days[new Date(date).getDay()]
}
