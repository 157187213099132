import React from 'react'
import clsx from 'clsx'
import { Switch, Route } from 'react-router-dom'
import { makeStyles, Drawer, Container } from '@material-ui/core'

import AuthGuard from './guard/AuthGuard'
import CompanyProvider from './components/CompanyProvider/CompanyProvider'
import CompanyView from './components/CompanyView'
import { HomePage } from './containers/Homepage/Homepage.js'
import { Report } from './components/Report/Report'
import EngineerView from './components/Engineers/EngineerView'
import ProjectsView from './components/Projects/ProjectsView'
import { SidebarList } from './components/SidebarList/SidebarList.js'

import './App.css'
import CompanyListView from './components/CompanyListView'
import TitleBar from './components/TitleBar'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 20
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  headerLink: {
    color: 'white',
    textDecoration: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(9)
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}))

function App () {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <AuthGuard>
      <CompanyProvider>
        <div className={classes.root}>
          <TitleBar open={open} setOpen={setOpen} />
          <Drawer
            variant='permanent'
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
            }}
            open={open}
          >
            <div className={classes.appBarSpacer} />
            <SidebarList />
          </Drawer>

          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth='xl' className={classes.container}>
              <Switch>
                <Route path='/companies' render={() => <CompanyListView />} />
                <Route path='/company/:id' render={() => <CompanyView />} />
                <Route path='/reports/:companyId/:date/:reportId' render={() => <Report />} />
                <Route path='/engineer/:companyId/:engineerId' render={() => <EngineerView />} />
                <Route path='/projects/:companyId' render={() => <ProjectsView />} />
                <Route path='/' render={() => <HomePage />} />
              </Switch>
            </Container>
          </main>

        </div>
      </CompanyProvider>
    </AuthGuard>
  )
}

export default App
