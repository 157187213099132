import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'

import auth from '../components/authentic'
import AuthPages from '../containers/Login/AuthPages'
import history from '../shared/history'

const authLinks = [
  '/login', '/signup', '/change-password-request', '/change-password'
]

const AuthGuard = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(auth.isLoggedIn())

  useEffect(() => {
    if (!isLoggedIn && !authLinks.includes(history.location.pathname)) {
      history.push('/login')
    }
  })
  return (
    <Router history={history}>
      {!isLoggedIn
        ? <AuthPages setIsLoggedIn={setIsLoggedIn} />
        : props.children}
    </Router>
  )
}

export default AuthGuard
