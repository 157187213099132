import { Grid, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'

import { getAllProjectsByRange } from '../../api'
import StateHandler from '../StateHandler'
import { getActHours, getEtdHours, getPercentage } from '../../utils/projectHoursUtils'

const Projects = ({
  dateStart,
  dateEnd,
  companyId,
  projectLoaded,
  setProjectLoaded
}) => {
  const [err, setErr] = useState(null)
  const [records, setRecords] = useState([])

  const fn = useCallback((error, response) => {
    if (error) return setErr(new Error('failed to fetch projects'))

    const { data } = response
    setRecords(data)
    setProjectLoaded(true)
    setErr(null)
  }, [setErr, setProjectLoaded])

  useEffect(() => {
    setProjectLoaded(false)
    setErr(null)
    getAllProjectsByRange({ companyId, dateStart, dateEnd }, fn)
  }, [dateStart, dateEnd, companyId, setProjectLoaded, fn])
  return (
    <div>
      <h2>Projects</h2>
      <StateHandler isLoaded={projectLoaded} isErr={err}>
        {records.length
          ? (
            <>
              <p> Closed: {records.length} </p>
              {records.map(({
                name,
                githubUrl,
                projectKey,
                actHours,
                etdHours,
                startDate,
                actDeployDate,
                etdDeployDate
              }) => (
                <div key={projectKey} style={{ paddingBottom: '12px' }}>
                  <a style={{ textDecoration: 'none', margin: '0px', fontWeight: 'bold' }} href={githubUrl}>
                    {name}
                  </a>
                  <p style={{ margin: '0px' }}>
                    {getActHours(startDate, actDeployDate, actHours)} / {getEtdHours(startDate, etdDeployDate, etdHours)} / {getPercentage(actHours, etdHours, startDate, actDeployDate, etdDeployDate)}%
                  </p>
                </div>
              ))}

              <p>
                Total: {records.reduce((acc, v) => acc + getActHours(v.startDate, v.actDeployDate, v.actHours), 0)} / {records.reduce((acc, v) => acc + getEtdHours(v.startDate, v.etdDeployDate, v.etdHours), 0)}
              </p>
            </>
          )
          : (
            <Grid>
              <Typography variant='overline' display='block' gutterBottom>
                No projects found
              </Typography>
            </Grid>)}

      </StateHandler>
    </div>
  )
}

export default Projects
