import React from 'react'
import { mdToHtml } from '../../utils/answerHandlers'

const VagueWordingReport = ({ userReport }) => {
  return (
    <div>
      <h3> Vague Wording </h3>
      {userReport.map(user => (
        user.vagueWording.length
          ? (
            <div key={user.name}>
              <p>{user.name} - {user.vagueWording.length}</p>
              <ul>
                {user.vagueWording.map((answer, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: mdToHtml(answer.answer) }}
                  />
                ))}
              </ul>
            </div>
          )
          : ''
      ))}
    </div>
  )
}

export default VagueWordingReport
