export function getDateDifference (startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const diff = end.getTime() - start.getTime()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  return days
}

export function getActHours (startDate, endDate, actHours) {
  if (actHours) return actHours
  return getDateDifference(startDate, endDate) * 6
}

export function getEtdHours (startDate, endDate, etdHours) {
  if (etdHours) return etdHours
  return getDateDifference(startDate, endDate) * 6
}

export function getPercentage (actHours, etdHours, startDate, actDate, etdDate) {
  if (etdHours && actHours) return (actHours / etdHours).toFixed(2) * 100
  return (getActHours(startDate, actDate) / getEtdHours(startDate, etdDate)).toFixed(2) * 100
}
