import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import history from '../../shared/history'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    textDecoration: 'none',
    '& .MuiInputBase-input': {
      color: '#fff'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8'
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff'
    }
  },
  selectEmpty: {
    color: 'white'
  },
  select: {
    color: 'white'
  }
}))

const CompanySelect = ({ companies, selectedCompany, selectCompany }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    selectCompany(event.target.value)
    history.push('/')
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.select}
          labelId='company-select-label'
          id='company-select'
          value={selectedCompany}
          onChange={handleChange}
          defaultValue={selectedCompany}
        >
          {companies.map((company) => (
            <MenuItem key={company.id} value={company}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default CompanySelect
