import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import ViewListIcon from '@material-ui/icons/ViewList'
import Divider from '@material-ui/core/Divider'
import { CompanyContext } from '../CompanyProvider/CompanyProvider'

import ProjectList from './ProjectList'
import ReportList from './ReportList'

export const SidebarList = (props) => {
  const { selectedCompany } = useContext(CompanyContext)

  return (
    <>
      <List>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/'
        >
          <ListItem button>
            <ListItemIcon>
              <DashboardRoundedIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/companies'
        >
          <ListItem button>
            <ListItemIcon>
              <ViewListIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Companies' />
          </ListItem>
        </Link>

        <Divider />
      </List>

      <ReportList selectedCompany={selectedCompany} />
      <ProjectList selectedCompany={selectedCompany} />

    </>
  )
}
