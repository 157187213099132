import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { CompanyReportDetails } from './CompanyReportDetails.js'
import { getSingleCompany } from '../../api.js'

export const CompanyReports = (props) => {
  const { id } = useParams()
  const [company, setCompany] = useState([])
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const fn = (error, response) => {
    if (error) {
      setLoaded(true)
      setThrownError(error)
    } else {
      setLoaded(true)
      setCompany(response.data.name)
    }
  }
  useEffect(() => {
    getSingleCompany(id, fn)
  }, [id])

  return (
    <Grid>{thrownError ? <Grid>{`ERROR: ${thrownError}`}</Grid>
      : !loaded ? <Grid>Loading...</Grid> : CompanyReportDetails(id, company)}
    </Grid>
  )
}
