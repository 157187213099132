import AuthenticUI from 'authentic-ui'
import opts from './options'
import wrap from './wrap.js'
import { matchPath } from 'react-router-dom'
import history from '../../shared/history'

const navigate = function (route) {
  history.push(route)
}

const navHome = navigate.bind(null, '/')

const aui = bindAll(AuthenticUI({
  server: process.env.REACT_APP_AUTH_SERVER || 'https://adnet-id.adnet.vip',
  links: {
    signup: '/signup',
    login: '/login',
    changePasswordRequest: '/change-password-request',
    changePassword: '/change-password'
  },
  titles: {
    signup: 'Sign Up',
    login: 'Log In',
    changePasswordRequest: 'Request New Password'
  }
}), [
  'login',
  'signup',
  'confirm',
  'changePasswordRequest',
  'changePassword'

])

const customStyles = {
  box: 'box',
  title: 'title',
  error: 'error',
  links: 'links',
  form: 'form'
}

function bindAll (obj, keys) {
  keys.forEach(function (key) {
    if (typeof obj[key] !== 'function') return
    obj[key] = obj[key].bind(obj)
  })
  return obj
}

export default {
  client: aui.auth,
  login: wrap(aui.login.bind(null, { styles: customStyles }), navigate.bind(null, '/')),
  logout: function (route) {
    aui.logout(navigate.bind(null, route))
  },

  signup: wrap(aui.signup, {
    from: opts.from,
    subject: opts.welcomeSubject,
    confirmUrl: opts.confirmUrl,
    styles: customStyles,
    provide: { bodyTemplate: opts.welcomeTemplate }
  }),

  changePasswordRequest: wrap(aui.changePasswordRequest, {
    from: opts.from,
    subject: opts.changePasswordSubject,
    changeUrl: opts.changePasswordUrl,
    styles: customStyles,
    provide: { bodyTemplate: opts.changePasswordTemplate }
  }),

  confirm (params) {
    return wrap(aui.confirm, {
      email: params.email,
      styles: customStyles,
      confirmToken: params.confirmToken
    }, navHome)
  },

  changePassword () {
    const match = matchPath(window.location.pathname, {
      path: '/change-password/:email/:changeToken'
    })
    return wrap(aui.changePassword, {
      email: match?.params?.email,
      styles: customStyles,
      changeToken: match?.params?.changeToken
    }, navHome)
  },

  get (url, cb) {
    aui.auth.get(url, function (err, body) {
      if (err) {
        return err.statusCode === 401
          ? aui.auth.logout()
          : cb(err)
      }

      cb(null, body)
    })
  },

  post (url, data, cb) {
    aui.auth.post(url, data, function (err, body) {
      if (err) {
        return err.statusCode === 401
          ? aui.auth.logout()
          : cb(err)
      }

      cb(null, body)
    })
  },

  delete (url, cb) {
    aui.auth.delete(url, function (err, body) {
      if (err) {
        return err.statusCode === 401
          ? aui.auth.logout()
          : cb(err)
      }

      cb(null, body)
    })
  },

  email: function () { return aui.auth.email },
  authToken: function () { return aui.authToken() },
  isLoggedIn: function () { return Boolean(aui.authToken()) }
}
