import { apiUrl } from './config'

import auth from './components/authentic'

export const getCompanies = (fn) => {
  // GET Companies
  const url = `${apiUrl}/companies`
  return auth.get(url, fn)
}

export const getSingleCompany = (id, fn) => {
  const url = `${apiUrl}/companies/${id}`
  // Init Auth
  return auth.get(url, fn)
}

export const createCompany = (name, fn) => {
  const url = `${apiUrl}/companies`
  return auth.post(url, { name }, fn)
}

export const getCompanyReports = ({ id, startDate, endDate }, fn) => {
  const url = `${apiUrl}/companies/${id}/reports/${startDate}/${endDate}`
  // Init Auth
  return auth.get(url, fn)
}

export const getCompanyEngineers = (id, fn) => {
  const url = `${apiUrl}/companies/${id}/engineers`
  // Init Auth
  return auth.get(url, fn)
}

export const getReportAnswers = ({ companyId, date, reportId }, fn) => {
  const url = `${apiUrl}/answers/${companyId}/report/${date}/${reportId}`
  return auth.get(url, fn)
}

export const getCompanyAnswersByDate = ({ companyId, dateStart, dateEnd }, fn) => {
  const url = `${apiUrl}/answers/${companyId}/date/${dateStart}/${dateEnd}`
  return auth.get(url, fn)
}

export const getEngineer = (companyId, engineerId, fn) => {
  return auth.get(`${apiUrl}/engineers/${companyId}/${engineerId}`, fn)
}

export const updateEngineer = ({ engineerId, companyId, details }, fn) => {
  return auth.put(`${apiUrl}/engineers/${companyId}/${engineerId}`, { ...details }, fn)
}

export const getEngineerStanduplyAnswers = ({ companyId, engineerId, startDate, endDate }, fn) => {
  return auth.get(
    `${apiUrl}/answers/${companyId}/${engineerId}/${startDate}/${endDate}`,

    fn
  )
}

export const putFlagStandupAnswer = ({
  id,
  flagged,
  flaggedNotes
}, fn) => {
  return auth.put(
    `${apiUrl}/answers/flag/${id}`,
    { flagged, flaggedNotes },

    fn
  )
}

export const getAllProjects = ({ companyId }, fn) => {
  const url = `${apiUrl}/projects/${companyId}`
  return auth.get(url, fn)
}

export const getAllProjectsByRange = ({ companyId, dateStart, dateEnd }, fn) => {
  const url = `${apiUrl}/projects/closed/${companyId}/${dateStart}/${dateEnd}`
  return auth.get(url, fn)
}

export const createProject = ({ companyId, project }, fn) => {
  const url = `${apiUrl}/projects/${companyId}`
  return auth.post(url, project, fn)
}

export const updateProject = ({ projectKey, project }, fn) => {
  const url = `${apiUrl}/projects/${projectKey}`
  return auth.put(url, project, fn)
}

export const deleteProject = ({ projectKey }, fn) => {
  const url = `${apiUrl}/projects/${projectKey}`
  return auth.delete(url, fn)
}

export const getPRs = ({ companyId, dateStart, dateEnd }, fn) => {
  const url = `${apiUrl}/pull-requests/${companyId}/${dateStart}/${dateEnd}`
  return auth.post(url, {}, fn)
}

export const getIssues = ({ companyId, repo, label, dateStart, dateEnd }, fn) => {
  const url = `${apiUrl}/issues/${companyId}/${dateStart}/${dateEnd}`
  return auth.post(url, { repo, label }, fn)
}
