import React from 'react'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import AssessmentIcon from '@material-ui/icons/Assessment'

const ReportList = ({ selectedCompany }) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => { setOpen(!open) }

  return (
    <Link
      key={selectedCompany.id}
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/company/${selectedCompany.id}`}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AssessmentIcon fontSize='large' />
        </ListItemIcon>
        <ListItemText primary='Reports' />
      </ListItem>
    </Link>
  )
}

export default ReportList
