
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

import { getPRs } from '../../api'

import StateHandler from '../StateHandler'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  projectName: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  prList: {
    marginTop: '0px',
    marginBottom: '0px',
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}))

const prSortIndex = [
  'feat', 'fix', 'refactor', 'style', 'test', 'docs', 'perf', 'build', 'ci'
]

const GroupTitle = ({ title, prs, companyId }) => {
  const classes = useStyles()
  const issues = _
    .chain(title.split(','))
    .map(t => _
      .concat(
        ..._.map(prs, 'linkedProjects'),
        ..._.map(prs, 'linkedEpics'))
      .find(e => e.name === t))
    .compact()
    .value()

  return (
    <div>
      <Typography variant='h6' variantMapping={{ h6: 'h3' }} className={classes.projectName}>
        {issues.length > 0
          ? (
            issues.map((issue, index) => (
              <a key={index} href={_.get(issue, 'githubUrl')} target='_blank' rel='noopener noreferrer'>
                {
                  (issues.length > 1 && index + 1 !== issues.length)
                    ? `${issue.name}, `
                    : issue.name
                }
              </a>
            ))
          )
          : title}
        {
          prs[0].category !== 'project' && (
            <a href={`/projects/${companyId}`} target='_blank' rel='noopener noreferrer'>
              <IconButton color='primary'> <AddIcon /> </IconButton>
            </a>
          )
        }
      </Typography>
    </div>
  )
}

const PullRequests = ({
  dateStart,
  dateEnd,
  companyId,
  prLoaded,
  setPrLoaded
}) => {
  const classes = useStyles()

  const [records, setRecords] = useState([])
  const [prCount, setPrCount] = useState(0)
  const [err, setErr] = useState(null)

  const fn = useCallback((error, response) => {
    if (error) return setErr(error.message || error.toString())

    const { data = [] } = response
    setPrCount(data.length)
    setRecords(categorizePRs(data))
    setErr(null)

    setPrLoaded(true)
  }, [setPrLoaded])

  const fetchPullRequests = useCallback((dateStart, dateEnd) => {
    setPrLoaded(false)
    setErr(null)
    setPrCount(0)

    getPRs({
      companyId,
      dateStart,
      dateEnd
    }, fn)
  }, [companyId, setPrLoaded, fn])

  useEffect(() => {
    fetchPullRequests(dateStart, dateEnd)
  }, [companyId, dateStart, dateEnd, fetchPullRequests])

  return (
    <div>
      <h2>Pull Requests ({prCount})</h2>
      <StateHandler isErr={err} isLoaded={prLoaded}>
        {_.map(records, (categories, categoryKey) => {
          return (
            <div key={categoryKey}>
              {_.map(categories, (prs, title) => {
                return (
                  <div key={title}>
                    <GroupTitle title={title} prs={prs} companyId={companyId} />
                    <ul className={classes.prList}>
                      {_.map(prs, (pr, index) => {
                        return (
                          <li key={index}>
                            <a href={pr.link} target='_blank' rel='noopener noreferrer'>
                              {pr.title}
                            </a>
                          </li>
                        )
                      })}
                    </ul>

                  </div>
                )
              })}
            </div>
          )
        })}
      </StateHandler>
    </div>
  )
}

function categorizePRs (prs) {
  return _
    .chain(prs)
    .sortBy(pr => {
      const commitType = pr.title.split(':')[0]
      const index = _.findIndex(prSortIndex, type => type === commitType)
      return index === -1 ? prSortIndex.length : index
    })
    .groupBy(e => {
      if (e.link.split('/')[4] === 'analytics-dbt-models') return 'analytics'
      if (e.linkedProjects.length > 0) return 'project'
      if (e.linkedEpics.length > 0) return 'epic'
      return 'Bugs/Misc'
    })
    .map((value, key) => _
      .groupBy(value, e => {
        e.category = key
        if (key === 'analytics') return 'Analytics'
        if (key === 'project') return e.linkedProjects.map(pj => pj.name)
        if (key === 'epic') return e.linkedEpics.map(ep => ep.name)
        return 'Bugs/Misc'
      })
    )
    .sortBy(group => {
      const sortKey = _.first(_.keys(group))
      if (sortKey === 'Bugs/Misc') return 2
      if (sortKey === 'Analytics') return 1
      return 0
    })
    .value()
}

export default PullRequests
