import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './Report/Style'
import classnames from 'classnames'
import { putFlagStandupAnswer } from '../api'
import { mdToHtml } from '../utils/answerHandlers'

export default function StandupAnswerResponse ({
  response,
  reportMeta
}) {
  const [savedFlagged, setSavedFlagged] = React.useState(response.flagged)
  const [flagged, setFlagged] = React.useState(response.flagged)
  const [flaggedNotes, setFlaggedNotes] = React.useState(response.flaggedNotes)
  const [formChanged, setFormChanged] = React.useState(false)
  const classes = useStyles()
  const handleFlaggedChange = (e) => {
    setFlagged(prev => !prev)

    if (e.target.value) {
      setFlaggedNotes('')
    }
  }
  const handleFlaggedNotesChange = (e) => {
    setFlaggedNotes(e.target.value)
  }

  const fn = (error, response) => {
    if (error) {
      throw error
    } else {
      setSavedFlagged(prev => !prev)
      setFormChanged(false)
    }
  }

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault()

    putFlagStandupAnswer({
      id: response.id,
      flagged,
      flaggedNotes
    }, fn)
  }, [
    flagged,
    flaggedNotes,
    response.id
  ])

  const handleFormChange = React.useCallback(() => {
    if (!formChanged) {
      setFormChanged(true)
    }
  }, [
    formChanged,
    setFormChanged
  ])

  return (
    <Grid
      item
      className={classnames(classes.answerContainer, {
        [classes.answerFlagged]: savedFlagged
      })}
    >
      <p style={{ fontWeight: '800' }}>{response.question}</p>
      <div
        className={classes.correct}
        dangerouslySetInnerHTML={{ __html: mdToHtml(response.answer) }}
      />

      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <label htmlFor={`${response.answerId}Flagged`}>
          Flagged:
          <input
            id={`${response.answerId}Flagged`}
            type='checkbox'
            checked={flagged}
            defaultValue={flagged}
            onChange={handleFlaggedChange}
          />
        </label>

        {flagged && (
          <>
            <div>
              <label htmlFor={`${response.answerId}FlaggedNotes`}>Notes:
                <textarea
                  className={classes.answerFlaggedNotes}
                  id={`${response.answerId}FlaggedNotes`}
                  defaultValue={flaggedNotes || response.flagged_notes}
                  onChange={handleFlaggedNotesChange}
                />
              </label>
            </div>
          </>
        )}

        <div>
          <input type='submit' disabled={!formChanged} />
        </div>
      </form>
    </Grid>
  )
}
