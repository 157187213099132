import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import clsx from 'clsx'

import auth from '../components/authentic'
import CompanySelect from './SidebarList/CompanySelect'
import { CompanyContext } from './CompanyProvider/CompanyProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 20
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  headerLink: {
    color: 'white',
    textDecoration: 'none'
  },
  titleGrid: {
    textAlign: 'left'
  },
  companyGrid: {
    textAlign: 'center'
  },
  logoutGrid: {
    textAlign: 'right'
  }
}))

const TitleBar = ({ open, setOpen }) => {
  const classes = useStyles()
  const { companies, selectedCompany, selectCompany } = useContext(CompanyContext)
  const handleDrawerClick = () => {
    setOpen(!open)
  }

  const handleLogout = () => {
    auth.logout('/login')
  }
  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, open && classes.appBarShift)}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>

        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerClick}
          className={clsx(
            classes.menuButton,
            open && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>

        <Grid container spacing={3}>
          <Grid className={classes.titleGrid} item xs={4}>
            <Typography variant='h6' className={classes.title}>
              <Link to='/' className={classes.headerLink}>Superstruct Platform</Link>
            </Typography>
          </Grid>
          <Grid className={classes.companyGrid} item xs={4}>

            <CompanySelect
              companies={companies}
              selectedCompany={selectedCompany}
              selectCompany={selectCompany}
            />
          </Grid>
          <Grid className={classes.logoutGrid} item xs={4}>

            <Button
              style={{ color: 'white' }}
              onClick={handleLogout}
              href='/'
            >
        Logout
            </Button>
          </Grid>
        </Grid>

      </Toolbar>
    </AppBar>
  )
}

export default TitleBar
