import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './Report/Style'
import StandupAnswerResponse from './StandupAnswerResponse'

export default function StandupAnswer ({
  user,
  responses,
  reportMeta
}) {
  const classes = useStyles()

  return (
    <Grid container className={classes.responseContainer} key={user}>
      <Grid container className={classes.questionResponse}>
        <p style={{ fontSize: '25px', alignSelf: 'start' }}>{user}</p>
        {responses.map((response) => (
          <StandupAnswerResponse
            key={response.answerId}
            response={response}
            reportMeta={reportMeta}
          />))}
      </Grid>
    </Grid>
  )
}
