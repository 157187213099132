import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import EngineerStandupAnswers from '../Report/EngineerStandupAnswers'
import EngineerDetailsView from './EngineerDetailsView'
import { getEngineer } from '../../api'

export default function EngineerView () {
  const { companyId, engineerId } = useParams()
  const [engineer, setEngineer] = React.useState(null)
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const { path, url } = useRouteMatch()

  React.useEffect(() => {
    if (engineer) {
      setLoaded(true)
    }
  }, [
    engineer,
    setLoaded
  ])

  const engineerFn = (error, response) => {
    if (error) setThrownError(error)
    else if (response) setEngineer(response.data)
  }

  React.useEffect(() => {
    getEngineer(companyId, engineerId, engineerFn)
  }, []) // eslint-disable-line

  return (
    <Grid>
      {thrownError
        ? <Grid>{`ERROR: ${thrownError}`}</Grid> : !loaded ? <Grid>Loading...</Grid>
          : (
            <>
              <Typography component='h1' variant='h3'>{engineer.first_name} {engineer.last_name}</Typography>

              <nav>
                <Link to={`${url}/details`}>Details</Link>
                {' '}
                <Link to={`${url}/answers`}>Answers</Link>
              </nav>

              <Switch>
                <Route path={`${path}/details`}>
                  <EngineerDetailsView engineer={engineer} />
                </Route>
                <Route path={`${path}/answers`}>
                  <EngineerStandupAnswers />
                </Route>
              </Switch>
            </>
          )}
    </Grid>
  )
}
