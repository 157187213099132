import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, TextField } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { CompanyContext } from './CompanyProvider/CompanyProvider'

const CompanyListView = () => {
  const { companies, createCompany } = useContext(CompanyContext)
  const [newCompanyName, setNewCompanyName] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    createCompany(newCompanyName)
    setNewCompanyName('')
  }

  return (
    <>
      <Grid container justify='flex-end'>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            id='company-name'
            label='Company Name'
            variant='outlined'
            value={newCompanyName}
            onChange={(evt) => setNewCompanyName(evt.target.value)}
          />
          <Button
            variant='contained'
            size='large'
            color='primary'
            type='submit'
            style={{ marginLeft: '1rem' }}
          >
            CREATE COMPANY
          </Button>
        </form>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label='Company Table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='justify'>Engineers</TableCell>
              <TableCell align='justify'>Reports</TableCell>
              <TableCell align='justify'>Teams</TableCell>
              <TableCell align='right'>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>{company.name}</TableCell>
                <TableCell align='justify'>
                  <Link to={`/company/${company.id}/engineers`}>Link</Link>
                </TableCell>
                <TableCell align='justify'>
                  <Link
                    to={`/company/${company.id}/standups?filter=all`}
                  >
                    {(company.reports || []).length > 0 ? 'All, ' : 'All'}
                  </Link>
                  {(company.reports || []).map((report, index) => (
                    <Link
                      key={index}
                      to={`/company/${company.id}/standups?filter=${report}`}
                    >
                      {company.reports.length === index + 1 ? report : `${report}, `}
                    </Link>
                  )
                  )}
                </TableCell>
                <TableCell align='justify'>
                  {(company.teams || []).join(', ')}
                </TableCell>
                <TableCell align='right'>{company.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CompanyListView
