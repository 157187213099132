import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import { getCompanyEngineers } from '../../api'

export default function EngineersView () {
  const { id: companyId } = useParams()
  const [engineers, setEngineers] = React.useState([])
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const [activeStatus, setActiveStatus] = React.useState('Active')
  const [filteredEngineers, setFilteredEngineers] = React.useState([])

  const fn = (error, response) => {
    if (response) {
      setLoaded(true)
      const sortedEngineers = response.data.sort((a, b) => {
        if (a.first_name < b.first_name) return -1
        if (a.first_name > b.first_name) return 1
        return 0
      })
      setEngineers(sortedEngineers)
      setFilteredEngineers(sortedEngineers.filter(e => e.active))
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  React.useEffect(() => {
    getCompanyEngineers(companyId, fn)
  }, []) //eslint-disable-line

  const handleActiveStatusChange = (evt) => {
    setActiveStatus(evt.target.value)
    const filterByActiveStatus = engineers.filter((engineer) => {
      if (evt.target.value === 'All') return true
      return engineer.active === (evt.target.value === 'Active')
    })
    setFilteredEngineers(filterByActiveStatus)
  }

  return (
    <>
      <Grid>
        {thrownError
          ? <Grid>{`ERROR: ${thrownError}`}</Grid> : !loaded ? <Grid>Loading...</Grid>
            : (

              <Grid container>

                <Grid item xs={12}>
                  <FormControl fullWidth style={{ float: 'right' }}>
                    <InputLabel id='active-status-select-label'>Active Status</InputLabel>
                    <Select
                      labelId='active-status-select-label'
                      id='active-status-select'
                      value={activeStatus}
                      label='Status'
                      onChange={handleActiveStatusChange}
                    >
                      <MenuItem value='Active'>Active</MenuItem>
                      <MenuItem value='Inactive'>Inactive</MenuItem>
                      <MenuItem value='All'>All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  {filteredEngineers.map(engineer => (
                    <div key={engineer.id}>
                      <Link to={`/engineer/${companyId}/${engineer.id}`}>
                        {engineer.first_name} {engineer.last_name}
                      </Link>
                    </div>
                  ))}
                </Grid>

              </Grid>

            )}
      </Grid>
    </>
  )
}
