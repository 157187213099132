import React from 'react'
import { updateEngineer } from '../../api'
import { useParams } from 'react-router'

export default function EngineerDetailsView ({
  engineer: {
    id: engineerId,
    ...engineer
  }
}) {
  const prevValuesRef = React.useRef({ ...engineer })
  const [formValues, setFormValues] = React.useState({ ...engineer })
  const { companyId } = useParams()

  const engineerFn = (error, response) => {
    if (error) console.error(error)
  }

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault()

    const details = Object.keys(formValues).reduce((memo, key) => {
      let value = formValues[key].trim()
      if (key === 'active') value = value === '1'
      if (value !== prevValuesRef.current[key]) {
        memo[key] = value
      }

      return memo
    }, {})

    if (!Object.keys(details).length) {
      return
    }

    await updateEngineer({ engineerId, companyId, details }, engineerFn)
    prevValuesRef.current = formValues
  }, [
    engineerId,
    formValues,
    companyId
  ])

  const handleCheckboxChange = (e) => {
    e.persist()

    setFormValues(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.checked ? '1' : '0'
      }
    })
  }

  const handleChange = (e) => {
    e.persist()

    if (e.target.type === 'checkbox') {
      return
    }

    setFormValues(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} onChange={handleChange}>
      <div>
        <label htmlFor={`${engineer.id}FirstName`}>
          First Name:
          <input id={`${engineer.id}FirstName`} name='first_name' type='text' defaultValue={formValues.first_name} />
        </label>
      </div>

      <div>
        <label htmlFor={`${engineer.id}LastName`}>
          Last Name:
          <input id={`${engineer.id}LastName`} name='first_name' type='text' defaultValue={formValues.last_name} />
        </label>
      </div>

      <div>
        <label htmlFor={`${engineer.id}StanduplyUserId`}>
          Standuply User ID:
          <input id={`${engineer.id}StanduplyUserId`} name='standuply_user_id' type='text' defaultValue={formValues.standuply_user_id} />
        </label>
      </div>

      <div>
        <label htmlFor={`${engineer.id}Active`}>
          Active:
          <input id={`${engineer.id}Active`} name='active' type='checkbox' defaultChecked={formValues.active} onChange={handleCheckboxChange} />
        </label>
      </div>

      <div>
        <input type='submit' />
      </div>
    </form>
  )
}
