import React from 'react'
import {
  getMonthAndDay,
  getWeekCount,
  getDayOfWeek
} from '../../utils/dateUtils'

const NoResponseReport = ({ userReport, dateStart, dateEnd }) => {
  const isSameWeek = getWeekCount(dateStart, dateEnd) <= 1
  return (
    <div>
      <h3> No Response </h3>
      {userReport.map(user => (
        user.noResponse.length
          ? (
            <li key={user.name}>
              {user.name}: {user.noResponse.length}: {
                user.noResponse
                  .map(ans => isSameWeek
                    ? `${getDayOfWeek(ans[0].created || ans[0].report_date)}`
                    : `${getMonthAndDay(ans[0].created || ans[0].report_date)}`
                  )
                  .join(', ')
              }
            </li>
          ) : ''
      ))}
    </div>
  )
}

export default NoResponseReport
