import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { CompanyContext } from '../CompanyProvider/CompanyProvider.js'
import { getCompanyReports } from '../../api.js'

const getDate = () => {
  const now = new Date()
  now.setDate(now.getDate() - 10)
  now.setHours(0)
  now.setMinutes(1)
  return now
}

export const PopulateReports = (props) => {
  const { companies } = useContext(CompanyContext)
  const { search } = useLocation()

  const [records, setRecords] = useState([])
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(getDate)
  const [filteredReports, setFilteredReports] = useState([])
  const [activeFilter, setActiveFilter] = useState(
    new URLSearchParams(search).get('filter') || 'all'
  )

  const handleActiveFilterChange = (event) => {
    const selected = event.target.value
    setActiveFilter(selected)
    const filteredRecords = selected === 'all'
      ? records
      : records.filter(record => record.name === selected)
    setFilteredReports(filteredRecords)
    window.history.replaceState(null, null, `?filter=${selected}`)
  }

  const fn = (error, response) => {
    if (error) {
      throw error
    } else {
      setRecords(response.data)
      const filteredRecords = activeFilter === 'all'
        ? response.data
        : response.data.filter(report => report.name === activeFilter)
      setFilteredReports(filteredRecords)
    }
  }

  useEffect(() => {
    getCompanyReports({
      id: props.id,
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10)
    }, fn)
  }, [props.id, startDate, endDate]) // eslint-disable-line

  return (
    <>
      <label>
        Start Date:
        <input
          type='date'
          defaultValue={startDate.toISOString().substr(0, 10)}
          onBlur={e => setStartDate(new Date(e.target.value))}
        />
      </label>

      <label>
        End Date:
        <input
          type='date'
          defaultValue={endDate.toISOString().substr(0, 10)}
          onChange={e => setEndDate(new Date(e.target.value))}
        />
      </label>

      <Grid>

        <FormControl fullWidth>
          <InputLabel id='active-status-select-label'>Active Status</InputLabel>
          <Select
            labelId='active-status-select-label'
            id='active-status-select'
            value={activeFilter}
            label='Status'
            onChange={handleActiveFilterChange}
          >
            <MenuItem value='all'>All</MenuItem>
            {(
              companies
                .find(company => company.id === props.id)
                .reports || []
            ).map((report, index) => (
              <MenuItem key={index} value={report}>
                {report}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {filteredReports.length ? filteredReports.map((report) => {
          const reportDate = new Date(report.scheduled_at)

          return (
            <Grid item key={report.id}>
              <p>
                <Link to={location => `/reports/${props.id}/${report.scheduled_at.slice(0, 10)}/${report.id}`}>
                  {reportDate.getMonth() + 1}/{reportDate.getDate()}/{reportDate.getFullYear()}
                  {' - '}
                  {report.name}
                </Link>
              </p>
            </Grid>
          )
        }) : <h1>No Reports Available</h1>}
      </Grid>
    </>
  )
}
