import React, { forwardRef } from 'react'
import MaterialTableMain from 'material-table'
import { Autocomplete } from '@material-ui/lab'
import { cloneDeep } from 'lodash'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Chip from '@material-ui/core/Chip'

import {
  createProject,
  updateProject,
  deleteProject
} from '../../api'
import { TextField } from '@material-ui/core'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const columnsAll = ({ engineers }) => [
  { title: 'Name', field: 'name' },
  {
    title: 'Type',
    field: 'type',
    lookup: {
      'High-Impact': 'High-Impact',
      Project: 'Project',
      Ticket: 'Ticket',
      'Misc-Project': 'Misc-Project',
      'Ice-box': 'Ice-Box'
    }
  },
  {
    title: 'Slack',
    field: 'engineeringPostUrl',
    render: ({ engineeringPostUrl }) => (
      engineeringPostUrl &&
        <a href={`http://${engineeringPostUrl}`} target='_blank' rel='noopener noreferrer'>
        link
        </a>
    )
  },
  {
    title: 'GitHub',
    field: 'githubUrl',
    render: rowData => (
      <a href={`http://${rowData.githubUrl}`} target='_blank' rel='noopener noreferrer'>
        link
      </a>
    )
  },
  {
    title: 'Priority',
    field: 'priority',
    lookup: {
      High: 'High',
      Medium: 'Medium',
      Low: 'Low',
      'N/A': 'N/A'
    }
  },
  {
    headerStyle: { minWidth: '300px' },
    title: 'Engineers (Current)',
    field: 'currentEngineers',
    render: ({ currentEngineers }) => (
      <EngineerListRender
        selectedList={currentEngineers}
        engineers={engineers}
      />),
    editComponent: (props) => <EngineerSelect {...props} engineers={engineers} />
  },
  {
    title: 'Engineers (Deck)',
    field: 'deckEngineers',
    render: ({ deckEngineers }) => (
      <EngineerListRender
        selectedList={deckEngineers}
        engineers={engineers}
      />),
    editComponent: (props) => <EngineerSelect {...props} engineers={engineers} />
  },
  {
    title: 'Engineers (In The Hole)',
    field: 'inTheHoleEngineers',
    render: ({ inTheHoleEngineers }) => (
      <EngineerListRender
        selectedList={inTheHoleEngineers}
        engineers={engineers}
      />),
    editComponent: (props) => <EngineerSelect {...props} engineers={engineers} />
  },
  {
    title: 'Engineers (Prev)',
    field: 'previousEngineers',
    render: ({ previousEngineers }) => (
      <EngineerListRender
        selectedList={previousEngineers}
        engineers={engineers}
      />),
    editComponent: (props) => <EngineerSelect {...props} engineers={engineers} />
  },
  { title: 'Start Date', field: 'startDate', type: 'date' },
  { title: 'Estimated Deploy Date', field: 'etdDeployDate', type: 'date' },
  { title: 'Actual Deploy Date', field: 'actDeployDate', type: 'date' },
  {
    title: 'Days Left',
    render: data => {
      const days = Math.round(
        (new Date(data.etdDeployDate) - new Date()) / (24 * 60 * 60 * 1000)
      )
      if (days < 0) return 0
      return days
    }
  },
  {
    title: 'Status',
    field: 'status',
    lookup: {
      'To-Do': 'To-Do',
      'In-Progress': 'In-Progress',
      'Ready-To-Review': 'Ready-To-Revieww',
      Completed: 'Completed'
    }

  },
  { title: 'Estimated Hours', field: 'etdHours', type: 'numeric' },
  { title: 'Actual Hours', field: 'actHours', type: 'numeric' },
  {
    title: 'Hour Percentage',
    render: data => Math.round(data.actHours / data.etdHours * 100) || 0
  }
]

const EngineerListRender = ({ engineers, selectedList = [] }) => {
  const engineerList = selectedList.map(id => {
    return engineers.find(eng => eng.id === id)
  })
  return (
    <div>
      {engineerList.map(engineer => (
        engineer && (
          <Chip
            key={engineer.id}
            label={engineer.first_name + ' ' + engineer.last_name}
            color='primary'
            style={{ margin: '2px' }}
          />)
      ))}
    </div>
  )
}

const EngineerSelect = (props) => {
  const { engineers, value = [] } = props
  const selectedValue = value.map(e => engineers.find(eng => eng.id === e))
  return (
    <Autocomplete
      style={{ width: 300 }}
      multiple
      id='tags-standard'
      onChange={(e, value) => props.onChange(value.map(v => v.id))}
      value={selectedValue}
      options={engineers}
      getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label='Engineers'
        />
      )}
    />
  )
}

const ProjectTable = (props) => {
  const {
    companyId,
    companyName,
    projects,
    setProjects,
    engineers
  } = props

  const columns = cloneDeep(columnsAll({ engineers }))

  return (
    <MaterialTableMain
      title={`${companyName}: Projects`}
      data={projects}
      columns={columns}
      icons={tableIcons}
      options={{
        search: true,
        draggable: true,
        sorting: true
      }}
      editable={{
        onRowAdd: project =>
          new Promise((resolve, reject) => {
            createProject({ companyId, project },
              (err, res) => {
                if (err) reject(err)
                setProjects([...projects, res.data])
                resolve()
              })
          }),
        onRowUpdate: (project, oldData) =>
          new Promise((resolve, reject) => {
            const projectKey = oldData.projectKey
            updateProject({ projectKey, project },
              (err, res) => {
                if (err) reject(err)
                const dataUpdate = [...projects]
                const index = oldData.tableData.id
                dataUpdate[index] = res.data
                setProjects([...dataUpdate])
                resolve()
              })
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const projectKey = oldData.projectKey
            deleteProject({ projectKey },
              (err, res) => {
                if (err) reject(err)
                const dataDelete = [...projects]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                setProjects([...dataDelete])
                resolve()
              })
          })
      }}
    />
  )
}

export default ProjectTable
