import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect
} from 'react'

import CompanyReducer, { ACTION_TYPES } from './CompanyReducer'
import * as api from '../../api'

const initialState = {
  companies: [],
  selectedCompany: { id: '' }
}

const CompanyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CompanyReducer, initialState)

  const getCompanies = useCallback(() => {
    const fn = (err, response) => {
      if (err) return console.log(err)
      dispatch({
        type: ACTION_TYPES.GET_COMPANIES,
        payload: response.data
      })
      dispatch({ type: ACTION_TYPES.SELECT_COMPANY, payload: response.data[0] })
    }
    api.getCompanies(fn)
  }, [])

  const createCompany = useCallback((name) => {
    const fn = (err, response) => {
      if (err) return console.log(err)
      dispatch({
        type: ACTION_TYPES.CREATE_COMPANY,
        payload: response.data
      })
    }
    api.createCompany(name, fn)
  }, [])

  const selectCompany = useCallback(payload => {
    dispatch({ type: ACTION_TYPES.SELECT_COMPANY, payload })
  }, [])

  useEffect(() => {
    getCompanies()
  }, [getCompanies])

  return (
    <CompanyContext.Provider
      value={{
        companies: state.companies,
        selectedCompany: state.selectedCompany,
        getCompanies,
        createCompany,
        selectCompany
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export const CompanyContext = createContext(initialState)

export default CompanyContextProvider
