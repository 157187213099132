import React from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import StartEndDatePickers from '../StartEndDatePickers'
import { getEngineer, getEngineerStanduplyAnswers } from '../../api'
import useStartEndDates from '../../hooks/useStartEndDates'
import { useStyles } from './Style'
import { mdToHtml } from '../../utils/answerHandlers'

const _ = require('lodash')

export default function EngineerStandupAnswers () {
  const classes = useStyles()
  const { companyId, engineerId } = useParams()
  const [engineer, setEngineer] = React.useState(null)
  const [answers, setAnswers] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const { endDate, setEndDate, setStartDate, startDate } = useStartEndDates()

  React.useEffect(
    () => {
      if (engineer && answers) {
        setLoaded(true)
      }
    },
    [answers, engineer, setLoaded]
  )

  const answersFn = (error, response) => {
    if (error) setThrownError(error)
    else if (response) {
      const questions = _
        .chain(response.data)
        .map('question')
        .uniq()
        .value()

      const requiresSumCount = (questions[0] || '').includes('How many')

      const answers = _
        .chain(response.data)
        .groupBy('username')
        .map((value, key) => ({
          user: key,
          responses: value.map(
            (response, index) => ({
              ...response,
              answerId: index,
              requiresSumCount
            })
          )
        }))
        .sortBy('user')
        .value()

      setQuestions(questions)

      if (!answers.length) {
        return setAnswers(answers)
      }

      const responses = answers[0].responses.reduce((memo, answer) => {
        const date = new Date(
          answer.created.toString().match('T')
            ? answer.created
            : parseInt(answer.created, 10)
        )
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)

        const key = date.getTime()

        if (!memo[key]) {
          memo[key] = []
        }

        memo[key].push(answer)

        return memo
      }, {})

      answers[0].responses = Object.keys(responses)
        .reduce((memo, key) => {
          memo.push([new Date(parseInt(key, 10)), responses[key]])
          return memo
        }, [])
        .sort((a, b) => {
          return a[0] > b[0] ? -1 : a[0] < b[0] ? 1 : 0
        })

      setAnswers(answers)
    }
  }

  const engineerFn = (error, response) => {
    if (error) setThrownError(error)
    else if (response) setEngineer(response.data)
  }

  const fetchStanduplyAnswers = React.useCallback(
    (startDate, endDate) => {
      getEngineerStanduplyAnswers(
        { companyId, engineerId, startDate, endDate },
        answersFn
      )
    },
    [companyId, engineerId]
  )

  React.useEffect(
    () => {
      fetchStanduplyAnswers(
        startDate.toISOString().slice(0, 10),
        endDate.toISOString().slice(0, 10)
      )
    },
    [endDate, fetchStanduplyAnswers, startDate]
  )

  React.useEffect(() => {
    Promise.all([
      getEngineer(companyId, engineerId, engineerFn),
      fetchStanduplyAnswers(startDate.getTime(), endDate.getTime())
    ])
  }, []); // eslint-disable-line

  const handleStartChange = date => {
    setStartDate(date)
  }
  const handleEndChange = date => {
    setEndDate(date)
  }

  return (
    <Grid>
      <StartEndDatePickers
        startDate={startDate}
        endDate={endDate}
        onStartChange={handleStartChange}
        onEndChange={handleEndChange}
      />

      {thrownError ? (
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      ) : !loaded ? (
        <Grid>Loading...</Grid>
      ) : (
        <Grid container>
          {renderAllResponses(answers)}
        </Grid>
      )}
    </Grid>
  )

  function renderAllResponses (answers) {
    return (
      <Grid item>
        {answers.map(item => (
          <Grid
            container
            className={classes.responseContainer}
            key={item.responses[0][0].getTime()}
          >
            <Grid container className={classes.questionResponse}>
              <table style={{ fontSize: '80%', borderCollapse: 'separate', borderSpacing: 10 }}>
                <thead>

                  <tr>
                    <th style={{ minWidth: 100 }}>Date</th>
                    {item.responses[0][1][0].requiresSumCount && (
                      <th> Reviewed </th>
                    )}

                    {questions.map((question, index) => (
                      <th key={index}>{question}</th>
                    ))}

                  </tr>
                </thead>
                <tbody>
                  {item.responses.reverse().map(renderDateResponses)}
                </tbody>
              </table>
            </Grid>
          </Grid>
        ))}
      </Grid>)
  }

  function renderDateResponses ([date, responsesRaw], i) {
    const responses = cleanResponses(responsesRaw)
    const sumPR = parseFloat(responses[0].answer || 0) + parseFloat(responses[1].answer || 0)

    return (
      <tr style={{ verticalAlign: 'top' }} key={date.toISOString()}>
        <td>{date.toISOString().split('T')[0]}</td>

        {responses[0].requiresSumCount && (
          <td>
            <span style={sumPR ? {} : { color: 'white', fontWeight: 'bold', background: 'red', padding: 4, borderRadius: 8 }}>
              {sumPR}
            </span>
          </td>)}
        {responses.map(renderResponse)}
      </tr>
    )
  }

  function renderResponse (response, i) {
    return (
      <td key={response.question}>
        {response.answer.split('\n').map(line => (
          <div
            key={line}
            style={{ color: isWarning(line) ? 'red' : '' }}
            dangerouslySetInnerHTML={{ __html: mdToHtml(line) }}
          />
        ))}
      </td>)
  }
}

function isWarning (lineRaw) {
  const line = lineRaw
    .replace(/^\W+/, '')
    .replace(/^i will\s+/i, '')
  const watchFor = [
    /^work on/i,
    /^continue/i,
    /^start/i,
    /^explore/i,

    /^look(ing)? (into|for)/i
  ]

  let hasWarning = false

  watchFor.forEach(function (re) {
    if (line.match(re)) hasWarning = true
  })

  return hasWarning
}

function cleanResponses (responsesRaw) {
  return _
    .chain(responsesRaw)
    .groupBy('question')
    .map(rs => _.maxBy(rs, r => r.answer ? r.created : 0))
    .value()
}
