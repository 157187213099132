
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'

const StateHandler = ({ isErr, isLoaded, children }) => {
  if (isErr) {
    return (
      <Grid>
        <Typography variant='overline' display='block' gutterBottom>
          {isErr.message || isErr.toString()}
        </Typography>
      </Grid>)
  }

  if (!isLoaded) return <CircularProgress color='secondary' />

  return children
}

export default StateHandler
