import React, { useState, useEffect } from 'react'
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { CompanyReports } from './CompanyReports/CompanyReports'
import EngineersView from './Engineers/EngineersView'
import { getSingleCompany } from '../api'
import ActivityReports from './ActivityReports/ActivityReports'
import WeeklyReports from './WeeklyReports/WeeklyReports'

export default function Company (props) {
  const { id } = useParams()
  const { path, url } = useRouteMatch()
  const [company, setCompany] = useState({})
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const fn = (error, response) => {
    if (error) {
      setLoaded(true)
      setThrownError(error)
    } else {
      setLoaded(true)
      setCompany(response.data)
    }
  }
  useEffect(() => {
    getSingleCompany(id, fn)
  }, [id])

  return (
    <Grid>
      {thrownError
        ? <Grid>{`ERROR: ${thrownError}`}</Grid>
        : !loaded
          ? <Grid>Loading...</Grid>
          : (
            <Grid>
              <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item>
                  <div>
                    <h1>{company.name}</h1>
                    <ul style={{ listStyle: 'none', padding: '0' }}>
                      <li><Link to={`${url}/engineers`}>Engineers</Link></li>
                      <li><Link to={`${url}/standups`}>Standups</Link></li>
                      <li><Link to={`${url}/activity-reports`}>Activity Reports</Link></li>
                      <li><Link to={`${url}/weekly-reports`}>Weekly Reports</Link></li>
                    </ul>
                  </div>

                  <Switch>
                    <Route path={`${path}/engineers`}>
                      <EngineersView />
                    </Route>
                    <Route path={`${path}/standups`}>
                      <CompanyReports />
                    </Route>
                    <Route path={`${path}/activity-reports`}>
                      <ActivityReports />
                    </Route>
                    <Route path={`${path}/weekly-reports`}>
                      <WeeklyReports />
                    </Route>
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          )}
    </Grid>
  )
}
