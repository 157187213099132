import React, { useState, useRef } from 'react'
import { Button, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router'
import TurndownService from 'turndown'
import qs from 'qs'

import { getBehindDate, getReadableDateFromISO } from '../../utils/dateUtils'
import Issues from './Issues'
import PullRequests from './PullRequests'
import Projects from './Projects'
import Hiring from './Hiring'

const useStyles = makeStyles((theme) => ({
  textField: {
    '& > *': {
      marginRight: theme.spacing(1),
      width: '96px'
    }
  },
  dateTextField: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  paperItems: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    padding: theme.spacing(2)
  }
}))

const WeeklyReports = () => {
  const classes = useStyles()

  const history = useHistory()
  const { id } = useParams()
  const { search } = useLocation()
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  const initDateStart = query.start || getBehindDate(6).toISOString().slice(0, 10)
  const initDateEnd = query.end || new Date().toISOString().slice(0, 10)

  const [dateStart, setDateStart] = useState(initDateStart)
  const [dateEnd, setDateEnd] = useState(initDateEnd)

  const divRef = useRef(null)
  const [issueLoaded, setIssueLoaded] = useState(true)
  const [prLoaded, setPrLoaded] = useState(true)
  const [projectLoaded, setProjectLoaded] = useState(true)

  const [hiring, setHiring] = useState({
    nodeS1: 0,
    nodeS2: 0,
    nodeHired: 0,
    reactS1: 0,
    reactS2: 0,
    reactHired: 0,
    qaS1: 0,
    qaS2: 0,
    qaHired: 0
  })

  const handleSearchSubmit = e => {
    e.preventDefault()
    const dateStartValue = e.target.dateStart.value
    const dateEndValue = e.target.dateEnd.value
    setDateStart(dateStartValue)
    setDateEnd(dateEndValue)
    updateQuery({
      start: dateStartValue,
      end: dateEndValue
    }, history)
  }

  const handleReset = () => {
    const defaultDateStart = getBehindDate(6).toISOString().slice(0, 10)
    const defaultDateEnd = new Date().toISOString().slice(0, 10)
    setDateStart(defaultDateStart)
    setDateEnd(defaultDateEnd)
    setHiring({
      nodeS1: 0,
      nodeS2: 0,
      nodeHired: 0,
      reactS1: 0,
      reactS2: 0,
      reactHired: 0,
      qaS1: 0,
      qaS2: 0,
      qaHired: 0
    })
    updateQuery({
      start: defaultDateStart,
      end: defaultDateEnd
    }, history)
  }

  const download = (data, filename) => {
    const element = document.createElement('a')
    const file = new Blob([data], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = filename
    document.body.appendChild(element)
    element.click()
  }

  const handleDownloadReport = () => {
    const turndownService = new TurndownService()
    const markdown = turndownService
      .turndown(divRef.current)
      .replace(/(<([^>]+)>)/ig, '')
    return download(markdown, `Weekly Report ${dateStart} - ${dateEnd}.md`)
  }

  const handleHiringChange = (e) => {
    const { name, value } = e.target
    setHiring({ ...hiring, [name]: value })
  }

  return (
    <div>
      <Paper className={classes.paper} variant='outlined' elevation={3}>
        <form noValidate onSubmit={handleSearchSubmit}>
          <Grid container spacing={3}>
            <Grid md={3} item>
              <Typography variant='subtitle1' component='h5'>
                Date Range
              </Typography>
            </Grid>
            <Grid className={classes.dateTextField} item>
              <TextField
                label='Date Start'
                id='dateStart'
                name='dateStart'
                type='date'
                defaultValue={dateStart}
                variant='outlined'
                size='small'
              />

              <TextField
                label='Date End'
                id='dateEnd'
                name='dateEnd'
                type='date'
                defaultValue={dateEnd}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid md={3} item>
              <Typography variant='subtitle1' component='h5'>
                Node
              </Typography>
            </Grid>
            <Grid className={classes.textField} item>
              <TextField
                label='Stage 1'
                type='number'
                name='nodeS1'
                value={hiring.nodeS1}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Stage 2'
                type='number'
                name='nodeS2'
                value={hiring.nodeS2}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Hired'
                type='number'
                name='nodeHired'
                value={hiring.nodeHired}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid md={3} item>
              <Typography variant='subtitle1' component='h5'>
                React
              </Typography>
            </Grid>
            <Grid className={classes.textField} item>
              <TextField
                label='Stage 1'
                type='number'
                name='reactS1'
                value={hiring.reactS1}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Stage 2'
                type='number'
                name='reactS2'
                value={hiring.reactS2}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Hired'
                type='number'
                name='reactHired'
                value={hiring.reactHired}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid md={3} item>
              <Typography variant='subtitle1' component='h5'>
                QA
              </Typography>
            </Grid>
            <Grid className={classes.textField} item>
              <TextField
                label='Stage 1'
                type='number'
                name='qaS1'
                value={hiring.qaS1}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Stage 2'
                type='number'
                name='qaS2'
                value={hiring.qaS2}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Hired'
                type='number'
                name='qaHired'
                value={hiring.qaHired}
                onChange={handleHiringChange}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>

          <div className={classes.paperItems}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >Submit
            </Button>
            <Button
              variant='contained'
              size='small'
              type='reset'
              onClick={handleReset}
            >Reset
            </Button>

            <Button
              variant='contained'
              size='small'
              type='reset'
              disabled={!issueLoaded || !prLoaded || !projectLoaded}
              onClick={handleDownloadReport}
            >Download
            </Button>
          </div>

        </form>
      </Paper>

      <h1>Weekly Report: {getReadableDateFromISO(dateStart)} - {getReadableDateFromISO(dateEnd)}</h1>

      <div ref={divRef}>
        <Issues
          dateStart={dateStart}
          dateEnd={dateEnd}
          companyId={id}
          issueLoaded={issueLoaded}
          setIssueLoaded={setIssueLoaded}
        />

        <Projects
          dateStart={dateStart}
          dateEnd={dateEnd}
          companyId={id}
          projectLoaded={projectLoaded}
          setProjectLoaded={setProjectLoaded}
        />

        <Hiring hiring={hiring} />

        <PullRequests
          dateStart={dateStart}
          dateEnd={dateEnd}
          companyId={id}
          prLoaded={prLoaded}
          setPrLoaded={setPrLoaded}
        />
      </div>

    </div>

  )
}

function updateQuery (queryObject, history) {
  history.replace({ search: qs.stringify(queryObject) })
}

export default WeeklyReports
