import React from 'react'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

const ProjectList = ({ selectedCompany }) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => { setOpen(!open) }

  return (
    <Link
      key={selectedCompany.id}
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/projects/${selectedCompany.id}`}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AssignmentTurnedInIcon fontSize='large' />
        </ListItemIcon>
        <ListItemText primary='Projects' />
      </ListItem>
    </Link>
  )
}

export default ProjectList
