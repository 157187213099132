import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import StandupAnswer from '../StandupAnswer'
import { getReportAnswers } from '../../api'
import _ from 'lodash'

const omit = require('lodash/omit')

export const Report = (props) => {
  const { companyId, date, reportId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [newData, setData] = useState({})
  const [userObject, setUserObject] = useState([])
  const [reportMeta, setReportMeta] = useState({})

  // Get data from id of param's 'reportId'
  useEffect(() => {
    const fn = async (err, response) => {
      if (err) {
        throw err
      }
      await setData(response.data)
    }

    getReportAnswers({ companyId, date, reportId }, fn)
  }, [companyId, date, reportId])

  // if newData is altered, search, filter, sort, and concat newData into UserObj.
  useEffect(() => {
    if (newData?.answers?.length > 1) {
      const groupedByUser = _
        .chain(newData.answers)
        .groupBy('username')
        .map((value, key) => ({
          user: key,
          responses: value.map(
            (response, index) => ({ ...response, answerId: index })
          )
        }))
        .sortBy('user')
        .value()

      setUserObject(groupedByUser)
      setReportMeta(omit(newData, ['answers']))
      setLoaded(true)
    }
  }, [newData])

  const reportDate = new Date(newData?.scheduled_at)

  return (
    <Grid>
      {!loaded ? <h3>...Loading</h3> : (
        <Grid container>
          <h1 style={{ margin: '0 auto' }}>
            {newData.name} for {reportDate.getMonth() + 1}/{reportDate.getDate()}/{reportDate.getFullYear()}
          </h1>

          {userObject.map(item => (
            <StandupAnswer
              key={item.user}
              user={item.user}
              responses={item.responses}
              reportMeta={reportMeta}
            />))}
        </Grid>
      )}
    </Grid>
  )
}
