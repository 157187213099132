import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getAllProjects, getCompanyEngineers } from '../../api'
import { CompanyContext } from '../CompanyProvider/CompanyProvider'
import ProjectTable from './ProjectTable'

const ErrorHandler = (props) => {
  const { err, loaded, children } = props
  return (
    <>
      {err
        ? <>{`ERROR: ${err}, please refresh the page and try again.`}</> : !loaded
          ? <>Loading...</>
          : children}
    </>
  )
}

const ProjectsView = (props) => {
  const { companyId } = useParams()
  const { companies } = useContext(CompanyContext)
  const {
    name: companyName
  } = companies.find(company => company.id === companyId) || {}

  const [loaded, setLoaded] = useState(false)
  const [projects, setProjects] = useState([])
  const [engineers, setEngineers] = useState([])
  const [err, setErr] = useState(null)

  const handleAllProjects = (error, response) => {
    if (error) return setErr(error)
    if (response.error) {
      setErr(response.message)
      setLoaded(true)
    }
    if (!response.error) {
      setProjects(response.data)
      setLoaded(true)
    }
  }

  const handleAllEngineers = (error, response) => {
    if (error) return setErr(error)
    if (response.error) {
      setErr(response.message)
      setLoaded(true)
    }
    if (!response.error) {
      const engineers = response.data
        .filter(e => e.active)
        .sort((a, b) => {
          if (a.first_name < b.first_name) return -1
          if (a.first_name > b.first_name) return 1
          return 0
        })
      setEngineers(engineers)
      setLoaded(true)
    }
  }

  useEffect(() => {
    getAllProjects({ companyId }, handleAllProjects)
    getCompanyEngineers(companyId, handleAllEngineers)
  }, [companyId])

  return (
    <ErrorHandler err={err} loaded={loaded}>
      <ProjectTable
        companyName={companyName}
        projects={projects}
        setProjects={setProjects}
        engineers={engineers}
        companyId={companyId}
      />
    </ErrorHandler>
  )
}

export default ProjectsView
